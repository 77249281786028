<template>
  <div class="powerbi-container">
    <Spinner v-if="isLoading" />
    <h3
      v-if="isLoading"
      style="width:100%;text-align:center;"
    >Please wait until the Power BI data is resolved...</h3>
    <section
      v-show="!isLoading"
      ref="reportContainer"
      class="powerbi-section"
    />
  </div>
</template>

<script>
import { powerBIMixin } from '../mixins/powerBIMixin'
import Spinner from '../components/common/spinner'
import * as pbi from 'powerbi-client'
import { DataEventBus } from '../events/dataEvents'
export default {
  name: 'TicketDashboard',
  components: {
    Spinner
  },
  mixins: [
    powerBIMixin
  ],
  props: {
    msg: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isLoading: false,
      accessToken: '',
      embedUrl: '',
      powerbi: {}
    }
  },
  computed: {
    reportId () {
      return process.env.VUE_APP_POWER_BI_REPORT_ID
    }
  },
  async mounted () {
    DataEventBus.$on('refresh-data', async () => {
      this.isLoading = true
      await this.getAccessToken()
      this.powerbi.reset(this.$refs.reportContainer)
      await this.setPowerBIReport()
      this.isLoading = false
    })
    DataEventBus.$on('company-changed', async () => {
      this.isLoading = true
      await this.getAccessToken()
      this.powerbi.reset(this.$refs.reportContainer)
      await this.setPowerBIReport()
      this.isLoading = false
    })
    this.isLoading = true
    await this.getAccessToken()
    await this.setPowerBIReport()
    this.isLoading = false
  },
  methods: {
    async getAccessToken () {
      var rtn = await this.getToken()
      this.accessToken = rtn.accessToken
      this.embedUrl = rtn.embedUrl
    },
    setPowerBIReport: async function () {
      const permissions = pbi.models.Permissions.All
      const config = {
        type: 'report',
        tokenType: pbi.models.TokenType.Embed,
        accessToken: this.accessToken,
        embedUrl: this.embedUrl,
        id: this.reportId,
        pageView: 'fitToWidth',
        permissions: permissions,
        settings: {
          // filterPaneEnabled: false,
          navContentPaneEnabled: false
        }
      }

      this.powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory)

      var reportContainer = this.$refs.reportContainer
      var report = this.powerbi.embed(reportContainer, config)

      // Report.off removes a given event handler if it exists.
      report.off('loaded')

      // Report.on will add an event handler which prints to Log window.
      // report.on('loaded', function () {
      //  console.log('loaded')
      //  var tabs = document.getElementsByClassName('pane.sections')[0]
      //  console.log(tabs)
      // })

      // Report.off removes a given event handler if it exists.
      // report.off('rendered')

      // Report.on will add an event handler which prints to Log window.
      // report.on('rendered', function () {
      //  console.log('rendered')
      //  var tabs = document.getElementsByClassName('pane.sections')[0]
      //  console.log(tabs)
      // })

      report.on('error', function (event) {
        console.log(event.detail)

        report.off('error')
      })

      report.off('saved')
      report.on('saved', function (event) {
        console.log(event.detail)
        if (event.detail.saveAs) {
          console.log('In order to interact with the new report, create a new token and load the new report')
        }
      })
    }
  }
}
</script>
<style scoped>
  .powerbi-container {
    width: calc(100% - 20px);
    height: calc(100vh - 140px);
    margin: 20px 10px 0 10px;
  }
  .powerbi-section {
    height: 100%;
  }
</style>
