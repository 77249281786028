import powerBIAPI from '../communications/interceptors/apiInterceptor'
export const powerBIMixin = {

  methods: {
    async getToken () {
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      var cData = this.$store.getters.userid + '_' + this.$store.getters.currentCompany.id
      var payload = '{"CustomData":"' + cData + '"}'
      var apiURL = 'powerbi/auth/token/'
      var data = ''
      try {
        await powerBIAPI.post(apiURL, payload, axiosConfig).then((reponse) => {
          data = reponse.data
        })
      } catch (error) {
        console.log('Error calling getToken with apiURL: ', apiURL)
      }
      return data
    }
  }
}
